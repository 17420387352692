import NextImage, { ImageProps } from 'next/image'
import { getImageUrl } from '@util/images'
type ImageParams = {
    width: number
    height?: number
    aspectRatio?: string
    fit?: string
}
export const Image = ({
    imageKey,
    imageParams,
    ...props
}: { imageParams: ImageParams; imageKey: string } & ImageProps) => {
    return (
        <NextImage
            style={{ objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%' }}
            {...props}
            alt={props.alt}
            loader={() => getImageUrl(imageKey, { ...imageParams })}
        />
    )
}

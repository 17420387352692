import styled from 'styled-components'

export const CarTag = styled.div<{ color: string; backgroundColor: string }>`
    display: grid;
    opacity: 0.9;
    position: relative;
    font-size: 12px;
    white-space: nowrap;
    cursor: default;
    user-select: none;
    padding: 4px 6px;
    border-radius: 4px;
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
`

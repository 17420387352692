import { Image } from '@components/global/Image'
import { useMediaQuery } from '@hooks/mediaQuery'
import { device } from '@util/responsive'
import styled from 'styled-components'

function useImageSize(): { width: number; height: number } {
    const isDesktop = useMediaQuery(device.laptop)
    const isTabletS = useMediaQuery(device.tabletS)
    if (isDesktop) {
        return { width: 640, height: 320 }
    } else if (isTabletS) {
        return { width: 960, height: 480 }
    } else {
        return { width: 640, height: 320 }
    }
}

export const CarImage = ({ src, preload }: { src: string; preload?: boolean }) => {
    const placeholderImage = 'MockCar.png'
    const { width, height } = useImageSize()
    const imageParams = {
        width,
        height,
        aspectRatio: '16:9',
        fit: 'crop',
        auto: 'format,compress',
    }

    if (!src) {
        return null
    }

    return (
        <ImageWrapper>
            <Image
                alt="car"
                src={placeholderImage}
                imageKey={src}
                imageParams={imageParams}
                width={imageParams.width}
                height={imageParams.height}
                priority={preload}
            />
        </ImageWrapper>
    )
}

const ImageWrapper = styled.div`
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    img {
        animation-name: fadeIn;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-fill-mode: both;
    }
`

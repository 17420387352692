import styled from 'styled-components'
import React, { useMemo } from 'react'
import { MONTHLY_SERVICE_FEE_DISPLAY } from '@util/constants'
import { formatPrice } from '@util/config'
import { useTranslation } from 'react-i18next'
import { convertToTranslationKey } from '@util/functions'
import { TranslationPrefix } from '@util/enums'
import { device } from '@util/responsive'
import { useLocaleConfig } from '@contexts/config'
import { useGlobalState } from '@contexts/global'
import { ProductType } from '@service/configuration.types'
import { InfoOutline } from './icons/InfoOutline'
import { Tooltip } from 'react-tooltip'

export const Card: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <CardContainer>
            <CardWrapper>{children}</CardWrapper>
        </CardContainer>
    )
}

export const ServiceFee = ({
    currency,
    serviceFeeAmount,
    textAlign,
    padding,
    tooltipContent,
    justify,
}: {
    currency: string
    serviceFeeAmount?: number
    textAlign?: string
    padding?: string
    tooltipContent?: string
    justify?: string
}) => {
    const { t } = useTranslation()
    const { cityCode } = useLocaleConfig()
    const { bookingState } = useGlobalState()

    const showMonthlyServiceFee = useMemo(
        () => bookingState.productType === ProductType.MONTHLY && MONTHLY_SERVICE_FEE_DISPLAY.includes(cityCode),
        [bookingState.productType, cityCode],
    )

    const renderTooltip = () => {
        if (!tooltipContent) return null

        const uniqueId = new Date().getTime()
        return (
            <>
                <InfoOutline width={18} className={`serviceFeeTooltip${uniqueId}`} />
                <Tooltip anchorSelect={`.serviceFeeTooltip${uniqueId}`} place="top" clickable>
                    <div style={{ maxWidth: '150px' }}>{tooltipContent}</div>
                </Tooltip>
            </>
        )
    }

    if (!showMonthlyServiceFee) return null

    return (
        <Flex justify={justify} padding={padding}>
            <SmallText fontSize="var(--size-12)" fontWeight={300} textAlign={textAlign}>
                {t('serviceFee', '+ {{currency}} {{serviceFeeAmount}} Service Fee', {
                    currency: currency,
                    serviceFeeAmount,
                })}
            </SmallText>
            {renderTooltip()}
        </Flex>
    )
}

export const TotalFee = ({
    isShown,
    totalRentalFee,
    currency,
}: {
    isShown: boolean
    totalRentalFee: number
    currency: string
}) => {
    const { t } = useTranslation()
    const currencyTranslated = t(convertToTranslationKey(currency, TranslationPrefix.currency), currency)

    if (!isShown) return null

    return (
        <SmallText className="lineHeight">
            {t('carCardTotal', ' total')} <span data-testid="totalRentalFee">{formatPrice(totalRentalFee)}</span>{' '}
            {currencyTranslated}
        </SmallText>
    )
}

const SmallText = styled.p<{ fontSize?: string; fontWeight?: number; textAlign?: string }>`
    text-align: ${({ textAlign = 'right' }) => textAlign};
    font-size: ${({ fontSize = 'var(--size-14)' }) => fontSize};
    margin: 0;
    padding: 0;
    font-weight: ${({ fontWeight }) => fontWeight};

    @media ${device.laptop} {
        font-size: calc(100vw / 1240 * 14);
    }
    @media ${device.container} {
        font-size: var(--size-14);
    }
`

const CardContainer = styled.div`
    background: white;
    padding: 13px 16px 12px 20px;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
`

export const CardWrapper = styled.div`
    border-radius: 16px 16px 0 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    height: 100%;
`
export const Flex = styled.div<{ direction?: string; noPadding?: boolean; justify?: string; padding?: string }>`
    display: flex;
    flex-direction: ${({ direction = 'row' }) => direction};
    justify-content: ${({ justify = 'flex-start' }) => justify};
    padding: ${({ padding = '0' }) => padding};
    align-items: center;
    gap: 5px;

    & > div {
        flex: 1;
    }

    svg {
        outline: none;
    }
`
